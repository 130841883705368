import { FC } from 'react';

import { ICON_NAMES } from '@belong/types';
import { FONT_COLOURS } from '@belong/themes';
import { Copy } from '@belong/ui-components';

import * as styles from './styles';

export interface IGHLargeLinkProps {
  'aria-current'?: any;
  'data-testid'?: string;
  href?: string;
  iconName?: ICON_NAMES;
  iconRight?: boolean;
  label: string;
  onClick?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  variant?: 'small' | 'large';
  secondLevelLink?: boolean;
}

const getColour = (href, onClick, ariaCurrent) => {
  const isLink = !!href || !!onClick;
  if (!isLink) {
    return FONT_COLOURS.DISABLED;
  }
  if (ariaCurrent) {
    return FONT_COLOURS.ACTIVE;
  }
  return FONT_COLOURS.LIGHT;
};

export const GHMobileLink: FC<IGHLargeLinkProps> = ({
  'aria-current': ariaCurrent,
  href,
  iconName,
  iconRight = false,
  label,
  onClick,
  onKeyDown,
  variant = 'large',
  ...wrapperProps
}: IGHLargeLinkProps) => {
  const colour = getColour(href, onClick, ariaCurrent);

  if (href) {
    return (
      <styles.Wrapper
        href={href}
        onClick={onClick}
        variant={variant}
        aria-current={ariaCurrent}
        onKeyDown={onKeyDown}
        {...wrapperProps}
      >
        {variant === 'small' && (
          <Copy hasColor={colour} as="span">
            {label}
          </Copy>
        )}
        {variant === 'large' && (
          <styles.StyledHeading hasColor={colour} as="span" variant="small">
            {label}
          </styles.StyledHeading>
        )}
      </styles.Wrapper>
    );
  }

  return (
    <styles.StyledButton
      onClick={onClick}
      variant={variant}
      aria-current={ariaCurrent}
      secondLevelLink={!!wrapperProps.secondLevelLink}
      aria-expanded="false"
      {...wrapperProps}
    >
      {iconName && <styles.StyledIcon name={iconName} hasColor={colour} variant={variant} iconRight={iconRight} />}
      {variant === 'small' && (
        <Copy hasColor={colour} as="span">
          {label}
        </Copy>
      )}
      {variant === 'large' && (
        <styles.StyledHeading hasColor={colour} as="span" variant="small">
          {label}
        </styles.StyledHeading>
      )}
    </styles.StyledButton>
  );
};

GHMobileLink.displayName = 'GHMobileLink';
