import { createGlobalStyle, css } from 'styled-components';
import styledNormalize from 'styled-normalize';

import { ID } from '@belong/types';

import { FONT_NAME, FONT_WEIGHT, COLOURS } from '../helpers/variables';

const bodyScrollLock = css`
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
`;

export const GlobalStyles = createGlobalStyle<{ hasBodyScrollLock?: boolean }>`
  ${styledNormalize}

  *,
  *:after,
  *:before {
    box-sizing: border-box;
  }

  body,
  dl, dt, dd,
  ul, ol, li,
  h1, h2, h3, h4, h5, h6,
  pre,
  form, fieldset,
  p, blockquote,
  figure, figcaption,
  table, th, td {
    margin: 0;
    padding: 0;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-family: ${FONT_NAME.PROXIMA_NOVA}, Arial, sans-serif;
    font-weight: ${FONT_WEIGHT.REGULAR};
    font-size: 1.7rem;
    line-height: 1.41;
    color: ${COLOURS.GREY_600};
    background-color: ${COLOURS.WHITE};

    ${(props): string | false | undefined =>
      props.hasBodyScrollLock &&
      `
      :has([aria-modal='true']),
      :has([data-testid='offcanvas-search'][aria-hidden='false']),
      :has([data-testid='offcanvas'][aria-hidden='false']) {
        ${bodyScrollLock}
      }

      // Can remove .body-scroll-lock and .has-fullscreen-chat selectors when FireFox starts to support :has.
      // Note: these are currently explicitly used to lock body when chat is fully covering page __via an iframe__,
      // so the :has selector will not find an aria-modal in the root document.
      // libs/ui-components/components/global/GlobalHeaderMobile/index.tsx
      // libs/ui-components/components/feedback/Modal/Modal.tsx
      // libs/livechat/src/ui/Chat.tsx
      // libs/chat-utils/ChatIframer.tsx
      &[class*=body-scroll-lock], &.has-fullscreen-chat {
        ${bodyScrollLock}
      }
    `}
  }

  #${ID.NEXT} {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  // The general rule is to follow default focus state patterns for standard HTML:
  // 1 Only interactive elements should show focus (e.g. if you can tab to it, show focus)
  // 2 Anything non-interactive shouldn't show focus styling (you shouldn't be able to tab to it, anyway)
  // 3 If we manage focus to a non-interactive element like a heading with tabindex="-1", don't show focus.
  [tabindex="-1"]:focus {
    outline: none;
  }
`;
