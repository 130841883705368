import React, { useEffect } from 'react';
import dynamic from 'next/dynamic';
import { ThemeProvider } from 'styled-components';
import { Cookies } from 'react-cookie';
import { AppProps } from 'next/app';

import { getNativeAppCookie } from '@belong/cookies';
import { GlobalStyles, THEME } from '@belong/themes';
import { PlatformScript, PolyfillScript } from '@belong/ui-components/components/global/Scripts';
import { initPlatformConfig, isBrowser, getCurrentEnvironment, logAppInfo } from '@belong/env';
import { withBasePath, withoutParams } from '@belong/url-utils';
import { ENVS } from '@belong/types';
import FeatureProviders from '@belong/providers';
import { getConfig as nextConfig } from '@belong/configs/next/config';

import { GlobalErrorHandler } from '@belong/logging';
import { FEATURES, FeaturesManager, FeatureTogglesContext } from '@belong/feature-toggles';

import { AnalyticsEvents } from '@belong/analytics';
import CanonicalLink from '@belong/ui-components/components/global/CanonicalLink';
import { ChatIframer } from '@belong/chat-utils';
import { SearchProvider } from '@belong/search';
import { useBlueGreen } from '@belong/react-hooks';
import AnalyticTracker from '../components/AnalyticsTracker';
import LayoutShell from '../components/LayoutShell';
import { routeConfig } from '../helpers/bootstrap';
import ErrorPage from './_error';

const Toolbox = dynamic(() => import('@belong/toolbox/src/view'));

export const { basePath, analytic } = nextConfig().publicRuntimeConfig;

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    BELONG: {
      analyticsEvents: AnalyticsEvents;
      redirectActivateSim?: (linkKey: string, url: string) => void;
      redirectOrderSim?: (linkKey: string, url: string) => void;
    };
  }
}

const setupAxe = () => {
  if (isBrowser() && getCurrentEnvironment() !== ENVS.PRODUCTION) {
    import('react-dom').then(ReactDOM => {
      import('@axe-core/react').then(axe => {
        axe.default(React, ReactDOM, 1000, {});
      });
    });
  }
};

type ICustomAppProps = Partial<AppProps>;

export default function PublicApp({ Component, pageProps }: ICustomAppProps) {
  const {
    cookie,
    correlationId,
    features,
    footerMenuContent,
    headerMenuContent,
    host,
    pageData,
    searchServerUrl,
    serverState
  } = pageProps || {};
  const allCookies = new Cookies(cookie);
  const { publicRuntimeConfig } = nextConfig();
  useBlueGreen(host);
  useEffect(() => {
    logAppInfo('Public', correlationId);
    setupAxe();
  }, [correlationId]);

  initPlatformConfig(getNativeAppCookie(cookie));
  FeaturesManager.init(correlationId, features);

  const additionalProviders: React.ComponentType<any>[] = [];

  return (
    <ThemeProvider theme={THEME}>
      <GlobalErrorHandler appRoutes={routeConfig.routes} />
      <PlatformScript />
      <PolyfillScript scriptName={'broadcastchannel.min.js'} />
      <PolyfillScript scriptName={'inert.min.js'} />
      <FeatureTogglesContext.Provider value={features}>
        <FeatureProviders cookies={allCookies} correlationId={correlationId} additionalProviders={additionalProviders}>
          <SearchProvider searchServerUrl={searchServerUrl} serverState={serverState}>
            <CanonicalLink pageUrl={withBasePath(withoutParams(pageProps.pageUrl))} />
            <AnalyticTracker page={pageProps} {...analytic} />
            <GlobalStyles hasBodyScrollLock />
            {!headerMenuContent || !footerMenuContent ? (
              <ErrorPage statusCode={500} />
            ) : (
              <LayoutShell
                pageAlert={pageData?.alert}
                shouldHaveLiveChat={!!pageData?.shouldHaveLiveChat}
                headerMenuContent={headerMenuContent}
                footerMenuContent={footerMenuContent}
                breadcrumbs={pageData?.breadcrumbs as any}
              >
                {Component && <Component {...pageProps} />}
                {features[FEATURES.LIVE_CHAT_IFRAME] && <ChatIframer />}
              </LayoutShell>
            )}
            {getCurrentEnvironment() !== ENVS.PRODUCTION && (
              <Toolbox pageProps={pageProps} runtimeConfig={publicRuntimeConfig} />
            )}
          </SearchProvider>
        </FeatureProviders>
      </FeatureTogglesContext.Provider>
    </ThemeProvider>
  );
}
