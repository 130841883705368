export enum ADDRESS_SUMMARY_TEST_ID {
  ROOT = 'address-summary',
  TITLE = 'address-summary-title',
  SUMMARY = 'address-summary-summary'
}

export enum SECTION_WRAPPER_TEST_ID {
  ROOT = 'section',
  CONTAINER = 'section-container',
  INNER_SECTION = 'section-inner-section'
}

export enum MODAL_TEST_IDS {
  ROOT = 'modal',
  CLOSE_BUTTON = 'modal-close-button',
  CONTENTS = 'modal-contents',
  DIALOG_BOX = 'modal-body'
}

export enum BUTTON_TEST_ID {
  PRIMARY = 'primary-button',
  SECONDARY = 'secondary-button',
  QUATERNARY = 'quaternary-button',
  TERTIARY = 'tertiary-button',
  GENERIC = 'generic-button',
  SWITCH_ADDRESS_INPUTS = 'switch-address-inputs-button',
  BUTTON_CHILDREN = 'children',
  BUTTON_ICON = 'icon'
}

export const GLOBAL_FOOTER = 'global-footer';

export const ACCORDION_LIST = 'accordion-list';
export const ACCORDION_DROPDOWN_TEST_ID = 'accordion-dropdown';

/** copied from ../constants/testIds.ts */

export const GLOBAL_BACK_TO_TOP_PORTAL = 'back-to-top-portal';
export const GLOBAL_BACK_TO_TOP_TRIGGER = 'back-to-top-trigger';

export const ALERT = 'alert';
export const ALERT_DISMISS_BUTTON = `${ALERT}-dismiss-button`;
export const ALERT_LINK = `${ALERT}-link`;

export const LOADING_BUTTON_PROGRESS = 'button-progress';

export const APPLY_CODE = {
  ROOT: 'apply-code',
  LABEL: 'apply-code-label',
  INPUT: 'apply-code-input',
  BUTTON: 'apply-code-button',
  DESCRIPTION: 'apply-code-description'
};

export const PICTURE = {
  ROOT: 'picture__root',
  IMG: 'picture__the-image'
};

export const ACTION_STRIP = {
  ROOT: 'action-strip__root',
  TITLE: 'action-strip__title'
};

export const ACTION_TILES = {
  ROOT: 'action-tile__root',
  OUTER_WRAPPER: 'action-tile__outer-wrapper',
  BUTTON: 'action-tile__button'
};

export const TILE_HEADER = {
  TAG: 'tile-header__tag'
};

export const DATA_BALANCE = {
  ROOT: 'data-balance__root',
  RHS_LABEL: 'data-balance__rhs-label'
};

export enum TILE_SELECTOR_TEST_ID {
  SIDE_LABEL = 'tile-selector-side-label'
}

export enum SECTION_INFO_TEST_ID {
  section = 'section-info',
  heading = 'section-info--heading',
  bodyContent = 'section-info--body',
  primaryButton = 'section-info--primary-button',
  secondaryButton = 'section-info--secondary-button',
  secondaryButtonLink = 'secondary-button',
  idvIntro = 'idv-intro'
}

export const NBN_AGENT_TEST_IDS = {
  ALL_PAGES_TOP_LAYOUT: 'top-layout',
  SUCCESS_ALERT: 'success-alert',

  // customer details
  CUSTOMER_DETAILS_FORM: 'customer-details-form',
  CUSTOMER_DETAILS_PAGE: 'customer-details-page',
  CUSTOMER_DETAILS_SUBMIT_BTN: 'customer-details-submit',
  CUSTOMER_DETAILS_BACK_BTN: 'customer-details-back',

  // mobile capture
  MOBILE_CAPTURE_FORM: 'mobile-capture-form',
  MOBILE_CAPTURE_PAGE: 'mobile-capture-page',
  MOBILE_CAPTURE_SUBMIT_BTN: 'mobile-capture-submit',
  MOBILE_CAPTURE_BACK_BTN: 'mobile-capture-back',

  // processing payment
  PROCESSING_PAYMENT: 'processing-payment-page',
  STATUS_SECTION: 'status-section',
  PAYMENT_IN_PROGRESS: 'payment-in-progress',
  PAYMENT_TIMED_OUT: 'payment-timed-out',
  PAYMENT_UNSUCCESSFUL: 'payment-unsuccessful',

  // mobile verify form
  MOBILE_VERIFY_FORM: 'mobile-verify-form',
  MOBILE_VERIFY_PAGE: 'mobile-verify-page',
  MOBILE_VERIFY_SUBMIT_BTN: 'mobile-verify-submit',
  MOBILE_VERIFY_BACK_BTN: 'mobile-verify-back',

  // terms & conditions
  TERMS_CONDITIONS_FORM: 'terms-conditions-form',
  TERMS_CONDITIONS_PAGE: 'terms-conditions-page',
  TERMS_CONDITIONS_SMS: 'terms-conditions-sms',
  TERMS_CONDITIONS_EMAIL: 'terms-conditions-email',
  TERMS_CONDITIONS_ACKNOWLEDGEMENT: 'terms-conditions-acknowledgement',
  TERMS_CONDITIONS_ACKNOWLEDGEMENT_CHECKBOX: 'terms-conditions-acknowledgement-checkbox',
  TERMS_CONDITIONS_BYO_CHECKBOX: 'terms-conditions-byo-checkbox',
  TERMS_CONDITIONS_SERVER_ERROR: 'terms-conditions-server-error',
  TERMS_CONDITIONS_SUBMIT_BTN: 'terms-conditions-submit',
  TERMS_CONDITIONS_BACK_BTN: 'terms-conditions-back',
  TERMS_CONDITIONS_ACCORDION: 'terms-conditions-accordion',
  TERMS_CONDITIONS_BILLING: 'terms-conditions-billing',

  // service detected
  SERVICE_DETECTED_PAGE: 'service-detected-page',

  // network connection device
  NETWORK_CONNECTION_DEVICE_HFC: 'network-connection-device-hfc',

  // modem, sim and ncd delivery
  MODEM_DELIVERY_PAGE: 'modem-delivery-page',
  SIM_DELIVERY_PAGE: 'sim-delivery-page',
  NCD_DELIVERY_PAGE: 'nbn-connection-device-delivery-page',
  DELIVERY_ADDRESS_FORM: 'delivery-address-form',
  DELIVERY_ADDRESS_OPTION_EXISTING: 'EXISTING',
  DELIVERY_ADDRESS_OPTION_ANOTHER: 'ANOTHER',
  DELIVERY_ADDRESS_SUBMIT_BTN: 'delivery-address-submit',
  DELIVERY_ADDRESS_BACK_BTN: 'deliver-address-back',

  // order confirmation
  ORDER_CONFIRMATION_PAGE_NO_BOOKING_DEFAULT: 'order-confirmation-details',
  ORDER_CONFIRMATION_PAGE_NO_BOOKING_HFC: 'order-confirmation-hfc',
  ORDER_CONFIRMATION_PAGE_NO_BOOKING_CONNECT_OUTSTANDING: 'order-confirmation-connect-outstanding',
  ORDER_CONFIRMATION_PAGE_BOOKING_CLASSIC: 'order-confirmation-appointment-details-classic',
  ORDER_CONFIRMATION_PAGE_BOOKING_NEW_STACK: 'order-confirmation-appointment-details',
  ORDER_CONFIRMATION_PAGE_APPOINTMENT_BOOKING_ISSUE: 'order-confirmation-appointment-booking-issue',
  ORDER_CONFIRMATION_PAGE_APPOINTMENT_ARRANGEMENT_REQUIRED: 'order-confirmation-appointment-arrangement-required',

  // buttons
  STICKY_BUTTONS_WRAPPER: 'sticky-buttons-wrapper',
  NEXT_BUTTON: 'next-button',
  PREVIOUS_BUTTON: 'previous-button'
};

export const PAYMENT_TEST_IDS = {
  // customer payment
  CUSTOMER_PAYMENT_LOADING: 'customer-payment-loading',

  CUSTOMER_PAYMENT_PAGE: 'customer-payment-page',
  CUSTOMER_PAYMENT_FORM: 'customer-payment-form',
  CUSTOMER_PAYMENT_RESULT: 'customer-payment-result',
  CUSTOMER_PAYMENT_SUBMIT_BTN: 'customer-payment-submit'
};

export const GREEN_CHOICE_TEST_IDS = {
  // green choice banner
  GREEN_CHOICE_BANNER_ROOT: 'green-banner-root',
  GREEN_CHOICE_BANNER_DESCRIPTION: 'green-banner-description',
  GREEN_CHOICE_BANNER_IMAGE: 'green-banner-image',

  // green choice form
  GREEN_CHOICE_FORM: 'form-green-choice',
  GREEN_FORM_RADIO_LIST_TEST_ID: 'form-green-choice-radio-list',
  GREEN_FORM_SUBMIT_TEST_ID: 'form-green-choice-submit-button',
  GREEN_CHOICE_SUCCESS: 'green-choice-success'
};

export enum PAYMENT_DETAILS_TEST_IDS {
  ROOT = 'PAYMENT_DETAILS_ROOT'
}
