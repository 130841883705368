import { StyledCSS } from '@belong/types';

import { FONT_COLOURS, isFocused } from '@belong/themes';
import styled, { css } from 'styled-components';
import { withLink, Icon, Heading, ButtonUnstyled } from '@belong/ui-components';

const CommonButtonStyles = css<{
  variant: 'small' | 'large';
  href?: any;
  secondLevelLink: boolean;
  onClick?: () => void;
}>`
  display: flex;
  height: 6rem;
  min-height: 6rem; // prevent collapse within flex container
  justify-content: start;
  align-items: center;
  padding-left: 5.6rem;
  padding-right: 1.6rem;
  text-decoration: none;
  user-select: none;

  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
      color: white;
    `}

  &:focus {
    ${(): StyledCSS => isFocused(true)}
  }

  &:hover,
  &:focus,
  &&:hover *,
  &&:focus * {
    color: ${FONT_COLOURS.ACTIVE};
  }

  ${props =>
    props.variant === 'large' &&
    css`
      padding-left: 3.2rem;
    `}

  ${props =>
    props.secondLevelLink &&
    css`
      padding-left: 4.8rem;
    `}
`;

export const Wrapper = withLink(styled.a<{
  variant: 'small' | 'large';
  href?: any;
  secondLevelLink: boolean;
  onClick?: () => void;
}>`
  ${CommonButtonStyles}
`);

export const StyledButton = styled(ButtonUnstyled)<{
  variant: 'small' | 'large';
  href?: any;
  secondLevelLink: boolean;
  onClick?: () => void;
}>`
  ${CommonButtonStyles}
`;

export const StyledIcon = styled(Icon)<{ variant: 'small' | 'large'; iconRight: boolean }>`
  margin-right: 0.8rem;
  width: 2.4rem;
  ${({ iconRight }) =>
    iconRight &&
    css`
      position: absolute;
      right: 2.6rem;
      margin-right: 0;
    `}
`;

export const StyledHeading = styled(Heading)`
  font-weight: 400;
  // force font size for 'md' breakpoint
  font-size: 2rem;
  line-height: 1;
`;
